import { Component } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import ToggleSwitch from "../ToggleSwitch";
import { IoIosArrowBack } from "react-icons/io";
import { formatFullDateString } from "../../utils/DateFormat";
import { formatAmountForDisplay } from "../../utils/DollarFormat";
import {
  fetchRequestJSON,
  fetchGetJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import { withHooksHOC } from "../Notifications/NotificationProvider";
import dayjs from "../../utils/Dayjs";

type Props = {
  unitData: any;
  toggle: any;
  handleEditBarToggle: any;
  ErrorNote: any;
};

type EditUnitState = {
  buildings: any[];
  owners: any[];
  suite: string;
  suite_number: string;
  suite_address: string;
  // suite_building: string;
  suite_model: string;
  suite_type: string;
  min_stay: string;
  heat_building: boolean;
  suite_size: number;
  bath_count: number;
  bed_count: number;
  fob_id: number;
  owner_email: string;
  // management_start_date: string;
  // isp: string;
  // isp_account: number;
  // rent_due: number;
  // rent_increase_date: string;
  // lease_start: string;
  // lease_end: string;
  // last_month_rent_hold: number;
  // deposit_hold: number;
  // password: string;
  // login: string;
  // unit_phone: string;
  // tvp: string;
  // hydro_provider: string;
  notes: string;
  percent_managed: number;
  // tv_account: string;
  managed_model: boolean;
  balcony: boolean;
  sofa_bed: boolean;
  bunk_bed: boolean;
  bed_config: string;
  building_id: number;
  unit_owner_id: number;

  isQuadable: boolean;
  quad_status: boolean;

  super_quad: boolean;
  super_quad_bunk: boolean;
  super_quad_notes: string;

  lease_start_date: string;
  lease_end_date: string;
};

class EditUnit extends Component<Props, EditUnitState> {
  constructor(props: any) {
    super(props);
    console.log(props);
    this.state = {
      buildings: [],
      owners: [],
      suite: this.props.unitData.suite,
      suite_number: this.props.unitData.suite_number,
      suite_address: this.props.unitData.suite_address,
      // suite_building: this.props.unitData.suite_building,
      suite_model: this.props.unitData.suite_model,
      managed_model: this.props.unitData.managed_model,
      percent_managed: this.props.unitData.percent_managed,
      suite_type: this.props.unitData.suite_type,
      min_stay: this.props.unitData.min_stay,
      fob_id: this.props.unitData.fob_id,
      owner_email: this.props.unitData.owner_email,
      // management_start_date: inputISO(
      //   this.props.unitData.management_start_date
      // ),
      heat_building: this.props.unitData.heat_building,
      suite_size: this.props.unitData.suite_size,
      bath_count: this.props.unitData.bath_count,
      bed_count: this.props.unitData.bed_count,
      // isp: this.props.unitData.isp,
      // isp_account: this.props.unitData.isp_account,
      // rent_due: this.props.unitData.rent_due,
      // rent_increase_date: inputISO(this.props.unitData.rent_increase_date),
      // lease_start: inputISO(this.props.unitData.lease_start),
      // lease_end: inputISO(this.props.unitData.lease_end),
      // last_month_rent_hold: this.props.unitData.last_month_rent_hold,
      // deposit_hold: this.props.unitData.deposit_hold,
      // password: this.props.unitData.password,
      // login: this.props.unitData.login,
      // unit_phone: this.props.unitData.unit_phone,
      // tvp: this.props.unitData.tvp,
      // tv_account: this.props.unitData.tv_account,
      // hydro_provider: this.props.unitData.hydro_provider,
      notes: this.props.unitData.notes,
      balcony: this.props.unitData.balcony,
      sofa_bed: this.props.unitData.sofa_bed,
      bunk_bed: this.props.unitData.bunk_bed,
      bed_config: this.props.unitData.bed_configuration,
      building_id: this.props.unitData.building_id,
      unit_owner_id: this.props.unitData.unit_owner_id,

      isQuadable: this.props.unitData.isQuadable,
      quad_status: this.props.unitData.quad_status,

      super_quad: this.props.unitData.super_quad,
      super_quad_notes: this.props.unitData.super_quad_notes,
      super_quad_bunk: this.props.unitData.super_quad_bunk,
      lease_start_date: this.props.unitData.lease_start_date,
      lease_end_date: this.props.unitData.lease_end_date,
    };
  }

  //     handleCorpToggle = (event) => {
  //       event.preventDefault();
  //       if ( this.state.corporate === false ) {
  //          this.setState({corporate: true});
  //       }

  //       else {
  //          this.setState({corporate: false});
  //      }

  //     }

  restrictNumbers = (event: any) => {
    let keyCode = event.charCode;
    if (48 <= keyCode && keyCode <= 57) {
      return;
    }
    event.preventDefault();
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let returnVal = this.target.value;
      if (this.target.id == "percent_managed" && returnVal != "") {
        returnVal = Math.min(100, Math.max(0, returnVal));
      }
      returnObj[this.target.id] = returnVal;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
    console.log(this.state.building_id);
  };

  changeUnitOwner = (event: any) => {
    event.preventDefault();
    console.log(event.target.value);
    this.setState({
      unit_owner_id: event.target.value,
    });
  };

  // Update fields with the current information
  componentDidMount = async () => {
    let unitId = Number(new URLSearchParams(window.location.search).get("id"));
    let unit = await fetchGetJSON(`${Constants.API_PATH_UNIT_GET}${unitId}`);
    let unitData = unit.data;
    let allOwners = await fetchGetJSON(Constants.API_PATH_OWNER_GET_ALL);
    allOwners.data.sort((ownerDataOne: any, ownerDataTwo: any) =>
      ownerDataOne.email.localeCompare(ownerDataTwo.email)
    );
    let ownerData = allOwners.data.find(
      (ownerItem: any) => ownerItem.id === unitData.unit_owner_id
    );

    let allBuildings = await fetchGetJSON(Constants.API_PATH_BUILDING_GET_ALL);

    console.log(unitData);
    this.setState({
      buildings: allBuildings.data,
      owners: allOwners.data,
      suite: unitData.suite_name,
      suite_number: unitData.suite_number,
      suite_address: unitData.location,
      managed_model: unitData.type === "Rental" ? false : true,
      // suite_building: buildingData.building_name,
      percent_managed: unitData.management_percentage,
      suite_type: unitData.suite_type,
      min_stay: unitData.min_stay,
      fob_id: unitData.fob_id,
      owner_email: ownerData.email,
      // management_start_date: inputISO(new Date(2022, 1, 1)),
      heat_building: true,
      suite_size: unitData.square_feet,
      bath_count: unitData.bathrooms,
      bed_count: unitData.bedrooms,
      // isp: unitData.internet_provider,
      // isp_account: unitData.internet_account,
      // rent_due: unitData.rent_due,
      // rent_increase_date: inputISO(new Date(unitData.rent_increase_date)),
      // lease_start: inputISO(new Date(unitData.lease_start_date)),
      // lease_end: inputISO(new Date(unitData.lease_end_date)),
      // last_month_rent_hold: unitData.last_month_rent_held,
      // deposit_hold: unitData.deposit_held,
      // password: unitData.internet_password,
      // login: unitData.internet_login,
      // unit_phone: unitData.unit_phone,
      // tvp: unitData.tv_provider,
      // tv_account: unitData.tv_account,
      // hydro_provider: unitData.hydro_provider,
      notes: unitData.notes,
      balcony: unitData.balcony,
      sofa_bed: unitData.sofa_bed,
      bunk_bed: unitData.bunk_bed,
      bed_config: unitData.bed_configuration,
      building_id: unitData.building_id,
      unit_owner_id: ownerData.id,

      isQuadable: unitData.isQuadable,
      quad_status: unitData.quad_status,

      super_quad: unitData.super_quad,
      super_quad_bunk: unitData.super_quad_bunk,
      super_quad_notes: unitData.super_quad_notes,

      lease_start_date: unitData.lease_start_date,
      lease_end_date: unitData.lease_end_date,
    });
  };

  // Create request body to edit unit fields
  handleClick = async () => {
    let unitId = Number(new URLSearchParams(window.location.search).get("id"));

    let unitEdit = {
      id: unitId,
      fields: {
        location: this.state.suite_address,
        suite_name: this.state.suite.trim(),
        suite_number: this.state.suite_number,
        square_feet: this.state.suite_size,
        bedrooms: this.state.bed_count,
        balcony: this.state.balcony,
        bunk_bed: this.state.bunk_bed,
        bathrooms: this.state.bath_count,
        building_id: this.state.building_id,
        notes: this.state.notes,
        sofa_bed: this.state.sofa_bed,
        suite_type: this.state.suite_type,
        type: this.state.managed_model ? "Managed" : "Rental",
        bed_configuration: this.state.bed_config,

        unit_owner_id: this.state.unit_owner_id,

        isQuadable: this.state.isQuadable,
        quad_status: this.state.isQuadable ? this.state.quad_status : false,

        super_quad: this.state.super_quad,
        super_quad_bunk: this.state.super_quad_bunk,
        super_quad_notes: this.state.super_quad_notes,

        lease_start_date: dayjs(this.state.lease_start_date).format(
          "YYYY-MM-DD"
        ),
        lease_end_date: dayjs(this.state.lease_end_date).format("YYYY-MM-DD"),
      },
    };

    const res = await fetchRequestJSON(
      "PUT",
      Constants.API_PATH_UNIT_EDIT,
      unitEdit
    );
    if (res.status === "Success") {
      window.location.reload();
    } else {
      const NotificationHandler = this.props.ErrorNote;
      NotificationHandler("Error", "Unit did not change", `${res.status}`);
    }
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = (function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    })();
    if (stateObject?.super_quad) {
      this.setState({
        super_quad_bunk: false,
      });
    } else if (stateObject?.super_quad_bunk) {
      this.setState({
        super_quad: false,
      });
    }
    this.setState(stateObject);
  };

  render() {
    let toggleEdit = this.props.toggle;
    let ProfileType = "Edit Unit";
    let editclassName =
      "opacity-0 pointer-events-none z-0 translate-x-96 overflow-y-scroll scroll-hide ";
    let editType = "booking";
    let content;
    let inactive = "";
    let tabIndex;
    let ManagedModel = "";
    let RentalModel = "";
    let modelEntry: any = [];

    if (this.state.managed_model !== true) {
      ManagedModel = "text-gray-300 ";
      RentalModel = "font-bold text-gray-600";
    } else {
      ManagedModel = "font-medium text-gray-600 ";
      RentalModel = "text-gray-300 ";
    }

    if (toggleEdit == true) {
      editclassName = " z-50 opacity-100 translate-x-0 lg:max-w-lg w-full  ";
    }

    content = (
      <form className="  flex flex-col pl-6 pr-12 py-4">
        <span className="text-gray-600 text-md font-bold mb-4 mt-2">
          General Info
        </span>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Address</span>
          <input
            id="suite_address"
            onChange={this.handleInputChange}
            value={this.state.suite_address}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Suite Number</span>
          <input
            id="suite_number"
            onChange={this.handleInputChange}
            value={this.state.suite_number}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Suite Name</span>
          <input
            id="suite"
            onChange={this.handleInputChange}
            value={this.state.suite}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        {/* <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Unit Phone</span>
          <input
            id="unit_phone"
            onChange={this.handleInputChange}
            value={this.state.unit_phone}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label> */}

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Building</span>
          <select
            id="building_id"
            onChange={this.handleInputChange}
            value={this.state.building_id}
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
          >
            {this.state.buildings
              .filter((building) => building.id === this.state.building_id)
              .map((building) => (
                <option selected disabled hidden>
                  {building.name}
                </option>
              ))}
            {this.state.buildings.map((building) => (
              <option value={building.id} id={building.id}>
                {building.building_name}
              </option>
            ))}
          </select>
        </label>

        <div className="block pl-3  mb-6 ">
          <span className="text-gray-400 text-xs">Suite Type</span>
          <select
            id="suite_type"
            onChange={this.handleInputChange}
            value={this.state.suite_type}
            className="  block w-full mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
          >
            <option>Studio</option>
            <option>1 Bedroom</option>
            <option>Junior 2 Bedroom</option>
            <option>2 Bedroom</option>
            <option>3 Bedroom</option>
            <option>Penthouse</option>
          </select>
        </div>

        {/* <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Min. Stay</span>
          <input
            id="min_stay"
            onChange={this.handleInputChange}
            value={this.state.min_stay}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label> */}

        <div className=" pl-3 flex flex-row w-full justify-between">
          <label className=" mb-5 w-1/4 ">
            <span className="text-gray-400 text-xs">Square Feet</span>
            <input
              id="suite_size"
              onChange={this.handleInputChange}
              onWheel={(e) => {
                e.currentTarget.blur();
              }}
              value={this.state.suite_size}
              type="number"
              className="mt-0 py-1  w-full   px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              placeholder=""
            />
          </label>

          <label className="  mb-5 w-1/4">
            <span className="text-gray-400 text-xs">Bedrooms</span>
            <input
              id="bed_count"
              onChange={this.handleInputChange}
              onWheel={(e) => {
                e.currentTarget.blur();
              }}
              value={this.state.bed_count}
              type="number"
              className="mt-0 py-1  w-full  px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              placeholder=""
            />
          </label>

          <label className="  mb-5 w-1/4">
            <span className="text-gray-400 text-xs">Bathrooms</span>
            <input
              id="bath_count"
              onChange={this.handleInputChange}
              onWheel={(e) => {
                e.currentTarget.blur();
              }}
              value={this.state.bath_count}
              type="number"
              className="mt-0 py-1  w-full  px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              placeholder=""
            />
          </label>
        </div>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Bedroom Configuration</span>
          <input
            id="bed_config"
            onChange={this.handleInputChange}
            value={this.state.bed_config}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder="Bed Size"
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Fob ID</span>
          <input
            id="fob_id"
            onChange={this.handleInputChange}
            value={this.state.fob_id}
            type="text"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          />
        </label>

        <span className="text-gray-600 text-md font-bold mb-4 mt-2">
          Rental / Managed
        </span>

        <div className=" flex flex-row pl-3 my-5 ">
          <span
            className={
              RentalModel + " transition-all duration-300 text-sm pr-3 "
            }
          >
            Rental Model
          </span>
          <ToggleSwitch
            id="managed_model"
            handleStateToggle={this.handleToggleChange}
            size="lg"
            onoff={this.state.managed_model}
          />
          <span
            className={
              ManagedModel + "transition-all duration-300 text-sm pl-3 "
            }
          >
            Managed
          </span>
        </div>
        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Lease Start Date</span>
          <input
            id="lease_start_date"
            onChange={this.handleInputChange}
            value={
              this.state.lease_start_date
                ? dayjs(this.state.lease_start_date).format("YYYY-MM-DD")
                : dayjs().format("YYYY-MM-DD")
            }
            type="date"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder={
              this.state?.lease_start_date ?? dayjs().format("YYYY-MM-DD")
            }
          />
        </label>

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Lease End Date</span>
          <input
            id="lease_end_date"
            onChange={this.handleInputChange}
            value={
              this.state.lease_end_date
                ? dayjs(this.state.lease_end_date).format("YYYY-MM-DD")
                : dayjs().format("YYYY-MM-DD")
            }
            type="date"
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder={
              this.state?.lease_end_date ?? dayjs().format("YYYY-MM-DD")
            }
          />
        </label>

        <div className=" flex flex-row pl-3 my-5 ">
          <span
            className={"font-bold transition-all duration-300 text-sm pr-3 "}
          >
            Is this Unit Quadable?
          </span>
          <ToggleSwitch
            id="isQuadable"
            handleStateToggle={this.handleToggleChange}
            size="lg"
            onoff={this.state.isQuadable}
          />
        </div>
        {this.state.isQuadable && (
          <>
            <div className=" flex flex-row pl-3 mb-3">
              <span className={"transition-all duration-300 text-sm pr-3 "}>
                Is the Unit Quaded (Quad Status)?
              </span>
              <ToggleSwitch
                id="quad_status"
                handleStateToggle={this.handleToggleChange}
                size="lg"
                onoff={this.state.quad_status}
              />
            </div>
            <div className=" flex flex-row pl-3 mb-3">
              <span className={"transition-all duration-300 text-sm pr-3 "}>
                Is this Unit a Super Quad?
              </span>
              <ToggleSwitch
                id="super_quad"
                handleStateToggle={this.handleToggleChange}
                size="lg"
                onoff={this.state.super_quad}
              />
            </div>
            <div className=" flex flex-row pl-3 mb-3">
              <span className={"transition-all duration-300 text-sm pr-3 "}>
                Is this Unit a Super Quad Bunk?
              </span>
              <ToggleSwitch
                id="super_quad_bunk"
                handleStateToggle={this.handleToggleChange}
                size="lg"
                onoff={this.state.super_quad_bunk}
              />
            </div>
            <label className="block pl-3 mb-5 mt-2">
              <b className="text-gray-400 text-xs">Super Quad Notes</b>
              <textarea
                id="super_quad_notes"
                onChange={this.handleInputChange}
                value={this.state.super_quad_notes}
                className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                rows={3}
              ></textarea>
            </label>
          </>
        )}

        <label className="block pl-3 mb-5">
          <span className="text-gray-400 text-xs">Owner Email</span>
          <select
            id="owner_email"
            onChange={this.changeUnitOwner}
            value={
              this.state.unit_owner_id == -1
                ? this.props.unitData.unit_owner_id
                : this.state.unit_owner_id
            }
            className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          >
            <option selected disabled hidden></option>
            {this.state.owners.map((owner) => (
              <option value={owner.id}>
                {owner.email} | {owner.first_name} {owner.last_name}
              </option>
            ))}
          </select>
        </label>

        {modelEntry}

        <div className="ml-3 mb-5 px-3 border rounded-sm border-solid py-4 shadow-sm flex flex-col">
          <div className=" flex flex-row mb-5">
            <div className={" block mr-10 w-32  "}>
              <div className="flex flex-row items-center justify-between mb-3">
                <span
                  className={
                    " transition-all duration-600 text-gray-600 text-sm mr-3"
                  }
                >
                  Balcony
                </span>

                <ToggleSwitch
                  id="balcony"
                  handleStateToggle={this.handleToggleChange}
                  className="justify-center align-center"
                  size="lg"
                  onoff={this.state.balcony}
                />
              </div>

              <div className="flex flex-row items-center justify-between mb-3">
                <span
                  className={
                    " transition-all duration-600 text-gray-600 text-sm mr-3"
                  }
                >
                  Sofa Bed
                </span>

                <ToggleSwitch
                  id="sofa_bed"
                  handleStateToggle={this.handleToggleChange}
                  className="justify-center align-center"
                  size="lg"
                  onoff={this.state.sofa_bed}
                />
              </div>

              <div className="flex flex-row items-center justify-between mb-3">
                <span
                  className={
                    " transition-all duration-600 text-gray-600 text-sm mr-3"
                  }
                >
                  Bunk Bed
                </span>

                <ToggleSwitch
                  id="bunk_bed"
                  handleStateToggle={this.handleToggleChange}
                  className="justify-center align-center"
                  size="lg"
                  onoff={this.state.bunk_bed}
                />
              </div>
            </div>

            {/* <div className={" block w-32  "}>
         <div  className="flex flex-row items-center justify-between ">
         <span className={" transition-all duration-600 text-gray-600 text-sm mr-3"}>Parking?</span>
         
        <ToggleSwitch id="parking" handleStateToggle={this.handleToggleChange} className="justify-center align-center" size="lg" onoff={this.state.parking} />
          
         </div>
         </div> */}
          </div>

          {/* 
         <div className=" flex flex-row ">
         <label className={" block w-32  "}>
         <div  className="flex flex-row items-center justify-between ">
         <span className={" transition-all duration-600 text-gray-600 text-sm mr-3"}>First Rent</span>
        
        <ToggleSwitch id="first_rent" handleStateToggle={this.handleToggleChange} className="justify-center align-center" size="lg" onoff={this.state.first_rent} />

          
         </div>
         </label>

   
         </div> */}
        </div>

        <label className="block pl-3 mb-5 mt-2">
          <span className="text-gray-400 text-xs">Additional Notes</span>
          <textarea
            id="notes"
            onChange={this.handleInputChange}
            value={this.state.notes}
            className="mt-1 block w-full rounded-md text-xs border-gray-200 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            rows={3}
          ></textarea>
        </label>
      </form>
    );

    return (
      <div
        className={
          editclassName +
          " bg-white lg:rounded-tl-xl lg:rounded-bl-xl flex flex-col overflow-y-scroll z-50  shadow-xl max-h-screen transform fixed top-0  right-0 transition-all duration-300  "
        }
      >
        {/* Top Title Bar */}
        <div className="transition-all lg:hidden duration-300  bg-gray-100 h-12 w-screen top-0 left-0 flex flex-row items-center justify-between shadow-xs">
          <button
            onClick={this.props.handleEditBarToggle}
            className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80"
          >
            <IoIosArrowBack className="text-gray-400 text-xl mx-auto ml-3 " />
          </button>

          <button className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80">
            <FiMoreHorizontal className="text-gray-400 text-2xl mx-auto mr-3 " />
          </button>
        </div>
        <div className="font-display flex pl-6 pr-14 flex-row py-6 w-auto items-center justify-between border border-solid">
          <span className=" font-black text-xl text-gray-700">
            {ProfileType}{" "}
          </span>
          <span className="italic font-sofia text-gray-400 text-lg font-thin">
            {this.state.suite}
          </span>
        </div>

        {content}

        {/* Submit Logic Below */}
        <div className="h-24 flex flex-row ">
          <button
            onClick={this.handleClick}
            className="transition duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-green-500 mx-auto rounded-md text-white font-bold shadow-sm mb-10 "
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

export default withHooksHOC(EditUnit);

// export function inputISO(date: Date) {
//   let year = date.getFullYear().toString();
//   let month = date.getMonth() + 1;
//   let dt = date.getDate();
//   let day = "";
//   let month2 = "";

//   if (dt < 10) {
//     day = "0" + dt;
//   } else {
//     day = dt.toString();
//   }
//   if (month < 10) {
//     month2 = "0" + month;
//   } else {
//     month2 = month.toString();
//   }

//   return year + "-" + month2 + "-" + day;
// }
