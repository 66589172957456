import React, { useEffect, useState } from "react";
import { Constants } from "../../api-requests/apiLinkConstants";
import { fetchGetJSON } from "../../api-requests/apiCallHelper";
import { LoadingGeneral } from "../../utils/LoadingComps/LoadingInvoice";
import PageSelector from "../../utils/PageSelector";
import { MdSearch } from "react-icons/md";
import dayjs from "../../utils/Dayjs";

export type NetSuiteInvoice = {
  links: [];
  createddate: string;
  duedate: string;
  foreigntotal: string;
  id: string;
  tranid: string;
  invoiceLink: string;
};
type State = {
  invoices: NetSuiteInvoice[]; // This is the list for all the invoice objects so I can use them to populate the list
  search: string;
  curpage: number;
  perpage: number;
  totalpages: number;
};

function InvoiceNetSuiteList() {
  const [curpage, setCurpage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [search, setSearch] = useState("");
  const [totalpages, setTotalpages] = useState(0);
  const [invoices, setInvoices] = useState<NetSuiteInvoice[]>([]);
  const [loaded, setLoaded] = useState(false);

  const loadData = async () => {
    let fetchInvoiceNetSuiteURL = `${Constants.API_PATH_LISTVIEW_INVOICES_NETSUITE}?page=${curpage}&perpage=${perpage}&nonCore=true`;
    if (search.length) {
      fetchInvoiceNetSuiteURL += `&search=${search}`;
    }

    const allInvoices = await fetchGetJSON(fetchInvoiceNetSuiteURL);

    setTotalpages(allInvoices.data.pages);
    setInvoices(
      allInvoices.data.rows.sort(
        (a: any, b: any) => parseInt(b.id) - parseInt(a.id)
      )
    );

    setLoaded(true);
  };

  const pageUp = async () => {
    let page = curpage + 1;
    if (page > totalpages) {
      page -= 1;
    }
    setCurpage(page);
    setLoaded(false);
    await loadData();
  };

  const pageDown = async () => {
    let page = curpage - 1;
    if (page == 0) {
      page += 1;
    }
    setCurpage(page);
    setLoaded(false);
    await loadData();
  };

  const setPerPage = async (val: number) => {
    setPerpage(val);
    setLoaded(false);
    await loadData();

    if (curpage > totalpages) {
      setCurpage(totalpages);
      await loadData();
    }
  };

  useEffect(() => {
    loadData();
  }, []); // Add necessary dependencies here

  return (
    <div className=" slide-in-blurred-top  font-display py-4">
      <div className="flex flex-row w-full">
        {/* Left Side */}
        <div className="flex flex-row w-full align-center items-center mb-6">
          {/* Search Bar */}
          <div className="flex flex-row border rounded-sm w-full lg:w-2/6 h-8 py-1">
            <MdSearch className="text-xl text-gray-400 mx-1" />
            <input
              onChange={(e) => setSearch(e.target.value)}
              className="text-sm searchBox w-full"
              placeholder="Search by Invoice ID or Transaction ID"
              id="search"
              value={search}
            ></input>
          </div>
          <button
            className="ml-2 bg-green-500 hover:bg-green-400 text-sm py-1 px-2 text-white"
            onClick={() => {
              loadData();
            }}
          >
            Search
          </button>

          {/* <span className="py-1 sm:px-4 font-medium margin text-gray-500">
            Filters:
          </span>
          <div className="flex flex-row flex-wrap">
            <button
              className={
                (this.state.filter_overdue
                  ? "bg-gray-200 hover:bg-gray-300"
                  : "bg-white hover:bg-gray-100") +
                " text-xs transition duration-100 mr-2 border border-solid text-gray-500 sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
              }
              onClick={() => {
                this.setState(
                  {
                    filter_overdue: !this.state.filter_overdue,
                  },
                  this.updateList
                );
              }}
            >
              Overdue
            </button>
            <button
              className={
                (this.state.filter_booking == 0
                  ? "bg-white hover:bg-gray-100 text-gray-500"
                  : this.state.filter_booking == 1
                  ? "text-green-500 bg-green-200 hover:bg-green-100"
                  : "text-red-500 bg-red-200 hover:bg-red-100") +
                " text-xs transition duration-100 mr-2 border border-solid sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
              }
              onClick={() => {
                let num = this.state.filter_booking;
                if (num === 2) {
                  num = 0;
                } else {
                  num++;
                }
                this.setState(
                  {
                    filter_booking: num,
                  },
                  this.updateList
                );
              }}
            >
              Booking
            </button>
            <button
              className={
                (this.state.filter_issues
                  ? "bg-gray-200 hover:bg-gray-300"
                  : "bg-white hover:bg-gray-100") +
                " text-xs transition duration-100 mr-2 border border-solid text-gray-500 sm:text-sm py-1 rounded-sm px-5 flex flex-row justify-center align-center"
              }
              onClick={() => {
                this.setState(
                  {
                    filter_issues: !this.state.filter_issues,
                  },
                  this.updateList
                );
              }}
            >
              Issues
            </button>
          </div> */}
          <PageSelector
            curpage={curpage}
            totalpages={totalpages}
            increase={pageUp}
            decrease={pageDown}
            setPerPage={setPerPage}
            perPageOptions={[10, 20, 30, 40, 50]}
          />
        </div>
      </div>

      {/* Invoice Table */}
      <table className="table-auto w-full mb-8">
        <thead>
          <tr className="hidden sm:table-row text-left font-normal text-gray-500 ">
            <th className="py-2 pl-4">ID</th>
            <th className="py-2  ">Transaction ID</th>
            <th className="py-2  ">Due Date</th>
            <th className="py-2  ">Cost</th>
            <th className="py-2  ">Created Date</th>
          </tr>
        </thead>

        <tbody>
          {loaded && invoices.length > 0 ? (
            invoices.map((invoice, index) => {
              // Alternate row background color
              const altBg = index % 2 !== 1 ? "bg-gray-50" : "bg-white";

              return (
                <tr
                  key={invoice.id}
                  className={`${altBg} grid grid-cols-1 sm:table-row cursor-pointer hover:bg-gray-100 border-t border-gray-400 w-full`}
                  onClick={() => window.open(invoice.invoiceLink, "_blank")}
                >
                  <td
                    className="w-full text-sm sm:text-base sm:w-16 py-4 sm:pl-4"
                    onClick={() => window.open(invoice.invoiceLink, "_blank")}
                  >
                    {invoice.id}
                  </td>
                  <td
                    className="w-full sm:w-36 font-light text-sm text-gray-500 sm:px-1 sm:py-4"
                    onClick={() => window.open(invoice.invoiceLink, "_blank")}
                  >
                    {invoice.tranid}
                  </td>
                  <td
                    className={`w-full sm:w-32 font-thin text-gray-600 sm:px-1 py-2 sm:py-4 ${
                      dayjs(invoice.duedate) < dayjs()
                        ? "text-red-500"
                        : "text-green-500"
                    }`}
                    onClick={() => window.open(invoice.invoiceLink, "_blank")}
                  >
                    {dayjs(invoice.duedate).format("LL")}
                  </td>
                  <td
                    className="w-full sm:w-32 font-medium text-gray-600 sm:px-1 py-0 sm:py-4"
                    onClick={() => window.open(invoice.invoiceLink, "_blank")}
                  >
                    ${invoice.foreigntotal}
                  </td>
                  <td
                    className="w-full text-sm sm:text-base sm:w-28 font-normal text-gray-400 sm:px-1 py-2 sm:py-4"
                    onClick={() => window.open(invoice.invoiceLink, "_blank")}
                  >
                    {dayjs(invoice.createddate).format("LL")}
                  </td>
                </tr>
              );
            })
          ) : loaded ? (
            <tr>
              <td colSpan={5} className="text-center py-4">
                No invoices found.
              </td>
            </tr>
          ) : (
            <LoadingGeneral count={10} cols={5} empty={loaded} />
          )}
        </tbody>
      </table>
    </div>
  );
}

export default InvoiceNetSuiteList;
