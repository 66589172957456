import React, { Component } from "react";
import PropTypes from "prop-types";
import Tab from "./Tab";

type Props = {
  children: Array<any>;
  warning: boolean;
  tabSelectButton?: any;
  fontSizeReducer?: boolean;
};

type State = {
  activeTab: string;
  warning: boolean;
};

class TabList extends Component<Props, State> {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
    warning: PropTypes.bool,
    tabSelectButton: PropTypes.func,
    hasSalesOrder: PropTypes.bool, // Validate prop type
  };

  static defaultProps = {
    fontSizeReducer: false, // Default value for hasSalesOrder
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      activeTab: this.props.children[0].props.label,
      warning: false,
    };
  }

  onClickTabItem = (tab: string) => {
    this.setState({ activeTab: tab });
    if (this.props.tabSelectButton) {
      this.props.tabSelectButton(tab.toLowerCase());
    }
  };

  render() {
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab },
    } = this;

    return (
      <div className="px-2 py-5  ">
        <ol
          className={`border-b-4 border-gray-300 scroll-hide pl-0 overflow-x-auto overflow-y-hidden whitespace-nowrap lg:whitespace-normal lg:overflow-none ${
            this.props.fontSizeReducer ? "text-sm" : ""
          }`}
        >
          {children.map((child) => {
            const { label } = child?.props;

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
                warning={this.props.warning}
              />
            );
          })}
        </ol>

        <div className="font-display">
          {children.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

export default TabList;
