import { Component, useEffect, useState } from "react";
import { Box, Modal, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/lab";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { fetchRequestJSON } from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import { ImCross } from "react-icons/im";
import { useNotification } from "../Notifications/NotificationProvider";
import ToggleSwitch from "../ToggleSwitch";
import store from "../../redux/store";
import Loading from "../LoadingWidget";

type Props = {
  id: number;
  checkOut?: any;
  open: boolean;
  closeModal: any;
  monthlyRate: number;
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 450,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function ExtensionCreateModal(props: Props) {
  const classes = useStyles();
  const thisDate = new Date(props.checkOut);
  const [date, setDate] = useState(thisDate.toISOString().split("T")[0]);
  const [override, setOverride] = useState(false);
  const [userTypes, setUserTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rate, setRate] = useState(props.monthlyRate / 30);
  const dispatch = useNotification();

  useEffect(() => {
    loadUserType();
  }, []);

  const loadUserType = async () => {
    let type = await store.getState().userReducer.userType;
    setUserTypes(type);
  };

  function onDateChange(event: any): void {
    event.preventDefault();
    setDate(event.target.value);
  }

  function HandleError(type: string, title: string, message: string) {
    dispatch({
      type: type.toUpperCase(),
      title: title,
      message: message,
    });
  }

  async function submitExtensionRequest() {
    setIsLoading(true); // Start loading
    try {
      const payload = {
        extend_until: date,
        booking_id: props.id,
        override: override,
        monthlyRate: +(rate * 30).toFixed(2),
      };
      let newExtension = await fetchRequestJSON(
        "POST",
        Constants.API_PATH_EXTENSION_CREATE,
        payload
      );
      if (newExtension.status === "Success") {
        HandleError(
          "Success",
          `Successfully Created Extension`,
          "Find its associated extension request in the extension tab"
        );
      } else {
        HandleError(
          "Error",
          `Error Creating Extension`,
          `${
            newExtension?.status === "Error"
              ? newExtension?.data
              : newExtension?.status
          }`
        );
      }
    } catch (error) {
      HandleError(
        "Error",
        "Error Creating Extension",
        "Please Check Extension Tab and Invoices."
      );
    } finally {
      setIsLoading(false); // Stop loading
      props.closeModal();
    }
  }

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={props.open}
      onClose={() => props.closeModal()}
    >
      <div className="appear bg-white shadow-lg rounded-lg h-2/5 w-1/2 border-4 border-gray-600 py-8 px-10 mx-auto flex flex-col align-self-center">
        {isLoading && (
          <div className="absolute inset-0 flex flex-col justify-center items-center bg-white bg-opacity-75 z-50 space-y-6">
            <h1 className="text-xl font-bold text-gray-700 text-center">
              Processing Extension Changes. Please wait till the modification is
              finished
            </h1>
            <Loading size="3x-large" type="big-spiral" />
          </div>
        )}
        {!isLoading && (
          <>
            <span className="text-xl font-bold text-gray-800 text-left pb-4">
              Extension Request
            </span>
            {(userTypes.includes("COO") ||
              userTypes.includes("Sales_Manager") ||
              userTypes.includes("Admin")) && (
              <div className={"block w-1/2 mb-8"}>
                <div className="flex flex-row items-center">
                  <span className={"transition-all duration-600 text-sm mr-3"}>
                    Override Extension Expiration
                  </span>
                  <ToggleSwitch
                    id="credit_fee"
                    handleStateToggle={() => setOverride(!override)}
                    className="justify-center align-center"
                    size="lg"
                    onoff={override}
                  />
                </div>
              </div>
            )}
            <div className="flex flex-row items-center">
              <label className="pl-3 mb-5 mx-auto flex flex-col">
                <span className="text-gray-400 text-xs">New Checkout Date</span>
                <input
                  id="date"
                  onChange={(e) => onDateChange(e)}
                  value={date}
                  type="date"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder=""
                />
              </label>
              <label className="pl-3 mb-5 mx-auto flex flex-col">
                <span className="text-gray-400 text-xs">Nightly Rate</span>
                <input
                  id="rate"
                  onChange={(e) =>
                    setRate(+parseFloat(e.target.value).toFixed(2))
                  }
                  value={rate}
                  type="number"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                  placeholder=""
                />
              </label>
              <label className="pl-3 mb-5 mx-auto flex flex-col">
                <span className="text-gray-400 text-xs">Monthly Rate</span>
                <input
                  disabled
                  id="rate"
                  value={+(rate * 30).toFixed(2)}
                  type="number"
                  className="mt-0 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black text-grey-300"
                  placeholder=""
                />
              </label>
            </div>
            <div className="h-24 mx-auto flex flex-col">
              <button
                onClick={() => submitExtensionRequest()}
                className="transition duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-gray-800 rounded-sm text-white shadow-sm"
              >
                Submit
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

export { ExtensionCreateModal };
