import { Component } from "react";
import Loading from "./LoadingWidget";

interface Props {
  title: string;
  content: any;
  handleModalToggle?: any;
  confirmButton?: any;
  onoff: boolean;
  yes?: boolean;
}

interface ModalState {
  title: string;
  content: any;
  onoff: boolean;
  isLoading: boolean;
}

class Modal extends Component<Props, ModalState> {
  constructor(props: any) {
    super(props);

    this.state = {
      title: this.props.title,
      content: this.props.content,
      onoff: this.props.onoff,
      isLoading: false,
    };
  }

  handleModal = (event: any) => {
    if (event.target.id === "outsideDiv" || event.target.id === "cancel") {
      this.props.handleModalToggle(this.props.onoff);
      if (this.state.onoff === false) {
        this.setState({ onoff: true });
      } else {
        this.setState({ onoff: false });
      }
    }
  };

  handleConfirm = async () => {
    this.setState({
      isLoading: true,
    });
    await this.props.confirmButton();

    this.setState({
      isLoading: false,
    });
  };

  render() {
    if (!this.props.onoff) {
      return null;
    }

    console.log(this.props.content);

    let confirm = "Confirm";
    let cancel = "Cancel";
    if (this.props.yes) {
      confirm = "Yes";
      cancel = "No";
    }

    return (
      <div
        id="outsideDiv"
        onClick={this.handleModal}
        className="fade-in fixed w-full h-full flex flex-col items-center justify-center bg-gray-600 bg-opacity-40 z-50"
      >
        <div className="appear bg-white shadow-lg rounded-lg h-auto max-w-screen-md py-8 px-10 mx-auto flex flex-col">
          {this.state.isLoading ? (
            // Render loading spinner or any loading widget
            <div className="flex justify-center items-center">
              <div className="text-xl font-semibold text-gray-700 mr-5">
                Is Processing Request, please wait a moment:
              </div>
              <Loading size="3x-large" type="big-spiral" />
            </div>
          ) : (
            <>
              <span className="text-xl font-bold text-gray-800 text-center">
                {this.state.title}
              </span>
              {this.props.confirmButton ? (
                <span className="text-xs text-center text-gray-400">
                  {this.props.content}
                </span>
              ) : (
                <>
                  <pre className="bg-gray-100 text-red-500 text-sm p-4 rounded-lg shadow overflow-auto max-h-96 mt-3">
                    {this.props.content}
                  </pre>
                </>
              )}
              <div className="flex flex-row justify-around w-full mt-5 mr-5">
                {this.props.confirmButton ? (
                  <>
                    <button
                      onClick={this.handleConfirm}
                      className="font-SF-Pro shadow-sm transition text-sm duration-100 w-36 bg-green-500 hover:bg-green-400 text-white font-bold py-2 rounded-sm pr-1 flex flex-row justify-center align-center"
                    >
                      {confirm}
                    </button>
                    <button
                      id="cancel"
                      onClick={this.handleModal}
                      className="font-SF-Pro shadow-sm transition text-sm duration-100 w-36 bg-gray-200 hover:bg-red-400 text-white font-bold py-2 rounded-sm pr-1 flex flex-row justify-center align-center"
                    >
                      {cancel}
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => this.props.handleModalToggle()}
                    className="font-SF-Pro shadow-sm transition text-sm duration-100 w-36 bg-green-500 hover:bg-green-400 text-white font-bold py-2 rounded-sm pr-1 flex flex-row justify-center align-center"
                  >
                    OK
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default Modal;
