const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
const localizedFormat = require("dayjs/plugin/localizedFormat");
const advancedFormat = require("dayjs/plugin/advancedFormat");

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.extend(advancedFormat);

export default dayjs;
export { dayjs };
