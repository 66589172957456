import React, { useEffect, useState } from "react";
import Loading from "../../LoadingWidget";
import { fetchGetJSON } from "../../../api-requests/apiCallHelper";
import { Constants } from "../../../api-requests/apiLinkConstants";
import { SalesOrderResponseFromBookingID } from "../../../utils/Types";
import { BsCheckCircle, BsXCircle } from "react-icons/bs";
import dayjs from "../../../utils/Dayjs";
// Define the custom props type
type SalesOrderProfileProps = {
  bookingId: string; // Booking ID as a string
  otherProp?: string; // Example of another optional prop
};

function SalesOrderProfile(props: SalesOrderProfileProps) {
  const [loading, setLoading] = useState(true);
  const [empty, setEmpty] = useState(false);
  const [salesOrderResponse, setSalesOrderResponse] =
    useState<SalesOrderResponseFromBookingID | null>(null);

  const loadData = async () => {
    let bookingId =
      props?.bookingId ||
      Number(new URLSearchParams(window.location.search).get("id"));

    let response: SalesOrderResponseFromBookingID = await fetchGetJSON(
      `${Constants.API_PATH_GET_SALESORDER_INVOICES}/${bookingId}`
    );

    if (response.status === "Success") {
      console.log(response.data.length);

      // if (response.data.length > 1) {
      //   //Filter out StayWell
      //   const filteredData = response.data.filter(
      //     (item) => item.customerName != "StayWell"
      //   );
      //   console.log(filteredData);
      //   response.data = filteredData;
      // }

      // setSalesOrderResponse(response);
      const filteredData =
        response.data.length > 1
          ? response.data.filter((item) => item.customerName != "StayWell")
          : response.data;

      setSalesOrderResponse({ ...response, data: filteredData });
      setLoading(false);
    } else {
      setLoading(false);
      setEmpty(true);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      {loading ? (
        <div className="flex flex-col bg-white mt-1 px-3 py-5 pb-1 justify-center items-center">
          <div className="mt-1 text-center text-lg">
            <h1 className="mb-5 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-2xl ">
              Grabbing Sales Order for Booking #ID: {props.bookingId}
            </h1>
          </div>
          <div className="mt-10">
            <Loading size="3x-large" type="big-spiral" />
          </div>
        </div>
      ) : empty ? (
        <div className="flex flex-col bg-white mt-1 px-3 py-5 pb-1 justify-center items-center">
          <div className="mt-1 text-center text-lg">
            <h1 className="mb-5 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-2xl ">
              Please note that no sales order was found. If the booking is{" "}
              <u>newly created</u>, the sales order may still be generating on
              NetSuite.
              <br />
              Try refreshing in a couple of seconds. If it still does not load,
              please contact the Dev Team.
            </h1>
          </div>
        </div>
      ) : (
        <div className="px-5">
          {salesOrderResponse?.data.map((salesOrder) => (
            <div
              key={salesOrder.salesOrderId}
              className="mb-10 shadow-lg rounded-lg bg-white"
            >
              <h1 className="text-xl font-bold mt-5 mb-3 px-5 py-2 bg-gradient-to-r from-gray-100 to-gray-200 rounded-t-lg shadow-sm">
                Sales Order{" "}
                <span className="text-green-500">
                  #{salesOrder.salesOrderId}
                </span>{" "}
                for {salesOrder.customerName}{" "}
                {salesOrder?.salesOrderItems[0]?.customerName &&
                  `(${salesOrder.salesOrderItems[0].customerName})`}
              </h1>

              {/* Sales Order Items Table */}
              <h2 className="text-lg font-semibold mb-1 px-5">
                Sales Order Items
              </h2>
              <table className="table-auto w-full mb-8 border-separate border-spacing-y-2 px-5">
                <thead className="bg-blue-100 text-blue-800">
                  <tr>
                    <th className="py-3 px-4 font-medium rounded-tl-lg">
                      Description
                    </th>
                    <th className="py-3 px-4 font-medium">Start Date</th>
                    <th className="py-3 px-4 font-medium">End Date</th>
                    <th className="py-3 px-4 font-medium">Rate</th>
                    <th className="py-3 px-4 font-medium">Quantity</th>
                    <th className="py-3 px-4 font-medium">Amount</th>
                    <th className="py-3 px-4 font-medium">Billing Date</th>
                    <th className="py-3 px-4 font-medium rounded-tr-lg">
                      Associated Invoice
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {salesOrder.salesOrderItems.map((item, index) => (
                    <tr
                      key={index}
                      className={`${
                        index % 2 === 0 ? "bg-white" : "bg-gray-50"
                      } hover:bg-blue-50 shadow-sm rounded-lg`}
                    >
                      <td className="py-3 px-4 border">
                        <span className="flex justify-center">
                          {item.description}
                        </span>
                      </td>
                      <td className="py-4 px-2 border">
                        {" "}
                        {/* Adjusted padding */}
                        <span className="flex justify-center text-sm">
                          {dayjs(item.start_date).format("YYYY-MM-DD")}
                        </span>
                      </td>
                      <td className="py-4 px-2 border">
                        {" "}
                        {/* Adjusted padding */}
                        <span className="flex justify-center text-sm">
                          {dayjs(item.end_date).format("YYYY-MM-DD")}
                        </span>
                      </td>
                      <td className="py-3 px-4 border text-green-500">
                        <span className="flex justify-center">
                          ${item.rate.toFixed(2)}
                        </span>
                      </td>
                      <td className="py-3 px-4 border">
                        <span className="flex justify-center">
                          {item.quantity}
                        </span>
                      </td>
                      <td className="py-3 px-4 border text-blue-500">
                        <span className="flex justify-center">
                          ${item.amount.toFixed(2)}
                        </span>
                      </td>
                      <td className="py-3 px-4 border">
                        <span className="flex justify-center">
                          {item?.associated_invoice_id ? (
                            <span className="text-sm flex justify-center">
                              Already Created
                            </span>
                          ) : (
                            dayjs(item?.billingDate).format("MM/DD/YYYY")
                          )}
                        </span>
                      </td>
                      <td className="py-3 px-4 border">
                        <span className="flex justify-center">
                          {item?.associated_invoice_id ? (
                            <button
                              onClick={() =>
                                window.open(
                                  item.associated_invoice_link,
                                  "_blank"
                                )
                              }
                              className="w-auto mr-3 px-2 border border-blue-500 text-xs font-display text-blue-500 hover:bg-blue-200"
                            >
                              View Invoice #{item.associated_invoice_id}
                            </button>
                          ) : (
                            "Invoice Not Created Yet"
                          )}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Invoice Items Table */}
              <h2 className="text-lg font-semibold mb-1 px-5">Invoice Items</h2>
              <table className="table-auto w-full mb-8 border-separate border-spacing-y-2 px-5">
                <thead className="bg-green-100 text-green-800">
                  <tr>
                    <th className="py-3 px-4 font-medium rounded-tl-lg">
                      Invoice ID
                    </th>
                    <th className="py-3 px-4 font-medium">Cost</th>
                    <th className="py-3 px-4 font-medium">Paid</th>
                    <th className="py-3 px-4 font-medium">Credit Applied?</th>
                    <th className="py-3 px-4 font-medium">Tax Applied?</th>
                    <th className="py-3 px-4 font-medium">Start Date</th>
                    <th className="py-3 px-4 font-medium">End Date</th>
                    {/* <th className="py-3 px-4 font-medium">Created At</th>
                    <th className="py-3 px-4 font-medium">Updated At</th> */}
                    <th className="py-3 px-4 font-medium rounded-tr-lg">
                      Full Info
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {salesOrder.invoiceItems.map((invoice, index) => (
                    <tr
                      key={index}
                      className={`${
                        index % 2 === 0 ? "bg-white" : "bg-gray-50"
                      } hover:bg-green-50 shadow-sm rounded-lg`}
                    >
                      <td className="py-3 px-4 border">
                        {" "}
                        <span className="flex justify-center">
                          {invoice.id}
                        </span>
                      </td>
                      <td className="py-3 px-4 border text-green-500">
                        <span className="flex justify-center">
                          ${invoice.cost}
                        </span>
                      </td>
                      <td className="py-3 px-4 border">
                        <span className="flex justify-center">
                          {invoice.paid ? (
                            <BsCheckCircle style={{ fill: "green" }} />
                          ) : (
                            <BsXCircle style={{ fill: "red" }} />
                          )}
                        </span>
                      </td>
                      <td className="py-3 px-4 border">
                        <span className="flex justify-center">
                          {invoice.credited ? (
                            <BsCheckCircle style={{ fill: "green" }} />
                          ) : (
                            <BsXCircle style={{ fill: "red" }} />
                          )}
                        </span>
                      </td>
                      <td className="py-3 px-4 border">
                        <span className="flex justify-center">
                          {invoice.isTaxable ? (
                            <BsCheckCircle style={{ fill: "green" }} />
                          ) : (
                            <BsXCircle style={{ fill: "red" }} />
                          )}
                        </span>
                      </td>
                      <td className="py-3 px-4 border">
                        <span className="flex justify-center">
                          {dayjs(invoice.start_date).format("YYYY-MM-DD")}
                        </span>
                      </td>
                      <td className="py-3 px-4 border">
                        <span className="flex justify-center">
                          {dayjs(invoice.end_date).format("YYYY-MM-DD")}
                        </span>
                      </td>
                      <td className="py-3 px-4 border">
                        <span className="flex justify-center">
                          <button
                            onClick={() =>
                              window.open(invoice.invoiceLink, "_blank")
                            }
                            className=" h-8 w-auto mr-3 px-2 border border-green-500 text-xs font-display text-green-500 hover:bg-green-200  "
                          >
                            View
                          </button>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SalesOrderProfile;
