import { Component, useRef } from "react";
import BreadCrumb from "../BreadCrumb";
import BookingList from "./BookingList";
import { MdPlaylistAdd } from "react-icons/md";
import TabList from "../TabList";
import {
  Link,
  withRouter,
  RouteComponentProps,
  useHistory,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import Label from "../LabelWrap";
import AgreementModal from "../AgreementModal";
import BookingQuickView from "../Bookings/BookingQuickView";
import React from "react";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import store from "../../redux/store";
import { blackoutType, extensionType, request } from "../../models/modelList";
import BookingCalendar from "./BookingCalendar";
import CorporateBookings from "./CorporateBookings";

type Props = {};

type State = {
  booking_id: number;
  check_in: string;
  check_out: string;
  corporate_id: string;
  tenant_name: string;
  booking_total: string;
  toggleQuickView: boolean;
  toggleAgreement: boolean;
  bookingResources: any[];
  bookingEvents: any[];
  blackoutEvents: any[];
  extensionEvents: any[];
};

type CalEvent = {
  resourceId: number;
  title: string;
  start: string;
  end: string;
  url: string;
  color?: string;
  textColor?: string;
};

// Booking event and resource typings
// type BookingResource = {
//   id: number;
//   title: string;
// };

// type BookingEvent = {
//   resourceId: number;
//   title: string;
//   start: string;
//   end: string;
// };

class Bookings extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      booking_id: -1,
      check_in: "",
      check_out: "",
      corporate_id: "",
      tenant_name: "",
      booking_total: "",
      toggleAgreement: false,
      toggleQuickView: false,
      bookingResources: [],
      bookingEvents: [],
      blackoutEvents: [],
      extensionEvents: [],
    };
  }

  // TODO: Refactor this function by figuring out how to rewrite this in a single line:
  // TODO: this.setState({ toggleAgreement: SOME BOOLEAN EXPRESSION HERE });
  handleAgreement = (state?: any) => {
    if (state && this.state.toggleAgreement === false) {
      this.setState({ toggleAgreement: true });
    } else {
      this.setState({ toggleAgreement: false });
    }
  };

  handleQuickView = () => {
    this.setState({ toggleQuickView: !this.state.toggleQuickView });
  };

  componentDidMount = async () => {
    this.updateResources();
    this.updateEvents();
  };

  // Populate the resources from the database bookings relation
  updateResources = async () => {
    /* let allBookings = await fetchGetJSON(
      `${Constants.API_PATH_BOOKING_GET_ALL}`
    );
    if (allBookings.status !== "Success") {
      return;
    }

    let allUnits = await fetchGetJSON(`${Constants.API_PATH_UNIT_GET_ALL}`);
    if (allUnits.status !== "Success") {
      return;
    }

    let allExtensions = await fetchGetJSON(
      `${Constants.API_PATH_EXTENSION_GET_ALL_UNCONFIRMED}`
    );

    let bookingResourceList: {
      id: any;
      title: string;
      eventAllow: boolean;
    }[];
    bookingResourceList = [];

    for (let i = 0; i < allBookings.data.length; i++) {
      // Need unit information since the resource title is the unit name
      const unitData = allUnits.data.find(
        (unitItem: any) => unitItem.id === allBookings.data[i].unit_id
      );

      // Bookings are soft deleted so check if they are supposed to show up or not
      if (!allBookings.data[i].cancelled) {
        if (
          !bookingResourceList.find(
            (element) => element.title == unitData.suite_name
          ) &&
          unitData.active
        ) {
          bookingResourceList.push({
            id: unitData.id,
            title: unitData.suite_name,
            eventAllow: true,
          });
        }
      }
    }
    this.setState({ bookingResources: bookingResourceList }); */
  };

  // Populate the resources from the database bookings relation
  updateEvents = async () => {
    /* let allBookings = await fetchGetJSON(Constants.API_PATH_BOOKING_GET_ALL);
    if (allBookings.status !== "Success") {
      return;
    }

    // Need tenant information since the the event title is the tenant name
    let allTenants = await fetchGetJSON(Constants.API_PATH_TENANT_GET_ALL);
    if (allTenants.status !== "Success") {
      return;
    }

    let bookingEventList: CalEvent[] = [];
    for (let i = 0; i < allBookings.data.length; i++) {
      let curTenant = allTenants.data.find(
        (tenantItem: any) => tenantItem.id === allBookings.data[i].tenant_id
      );
      // Bookings are soft deleted so check if they are supposed to show up or not
      if (!allBookings.data[i].cancelled) {
        bookingEventList.push({
          resourceId: allBookings.data[i].unit_id,
          title: curTenant
            ? `${curTenant.first_name} ${curTenant.last_name}`
            : "No Tenant Provided For This Booking",
          start: new Date(allBookings.data[i].check_in)
            .toISOString()
            .split("T")[0],
          end: new Date(allBookings.data[i].check_out)
            .toISOString()
            .split("T")[0],
          url: `${window.location.href}/BookingProfile/?id=${allBookings.data[i].id}`,
        });
      }
    }
    this.setState({ bookingEvents: bookingEventList });

    const allBlackouts: request<blackoutType[]> = await fetchGetJSON(
      Constants.API_PATH_BLACKOUT_GET_ALL
    );
    let blackoutEventList: CalEvent[] = [];
    if (allBlackouts.status == "Success") {
      for (let blackout of allBlackouts.data) {
        blackoutEventList.push({
          resourceId: blackout.unit_id,
          title: "Blackout",
          start: new Date(blackout.start_date).toISOString().split("T")[0],
          end: new Date(blackout.end_date).toISOString().split("T")[0],
          url: window.location.href.replace("bookings", "units"),
          color: "black",
        });
      }
    }

    this.setState({ blackoutEvents: blackoutEventList });

    const allExtensions: any = await fetchGetJSON(
      Constants.API_PATH_EXTENSION_GET_ALL_UNCONFIRMED
    );
    let extensionEventList: CalEvent[] = [];
    for (let extension of allExtensions) {
      extensionEventList.push({
        resourceId: extension.booking.unit_id,
        title: "Extension",
        start: new Date(extension.start).toISOString().split("T")[0],
        end: new Date(extension.extend_until).toISOString().split("T")[0],
        url: window.location.href.replace("bookings", "units"),
        color: "#F5F5D6",
        textColor: "black"
      });
    }
    console.log(extensionEventList)
    this.setState({ extensionEvents: extensionEventList }); */
  };

  render() {
    return (
      <div className=" font-display  bg-white lg:rounded-tl-lg pt-3 pb-20 lg:pt-0 lg:pb-0 ">
        <AgreementModal
          handleModalToggle={this.handleAgreement}
          onoff={this.state.toggleAgreement}
          title="Agreements"
          content="Select the properties that will be included in the agreement"
          confirmButton={this.handleAgreement}
        />
        <BookingQuickView
          handleModalToggle={this.handleQuickView}
          onoff={this.state.toggleQuickView}
          BookingId={this.state.booking_id}
        />

        <BreadCrumb
          rootPage="Bookings"
          sideButton="agreement"
          subPage=""
          buttonAction={this.handleAgreement}
        />
        <div className="fade-in px-4 lg:px-10 ">
          {/* Heading Info Area  */}
          <div className="flex flex-row mb-2 mt-14 px-2">
            {/* Left Side  */}
            <div className="flex flex-row justify-between w-full text-center items-center lg:items-start lg:flex-col lg:w-1/2">
              <h1 className="lg:hidden font-sofia text-gray-500 text-3xl font-bold ">
                Bookings
              </h1>
              <NewBookingLink />
            </div>
          </div>

          <TabList warning={false}>
            <Label label="List">
              <BookingList QuickViewButton={this.handleQuickView} />
            </Label>

            <Label label="Calendar">
              <div className=" py-4 fade-in ">
                <BookingCalendar />
              </div>
            </Label>
            <Label label="Corporate Bookings">
              <CorporateBookings QuickViewButton={this.handleQuickView} />
            </Label>
          </TabList>
        </div>
      </div>
    );
  }
}
export default Bookings;

function NewBookingLink() {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path, url } = useRouteMatch();

  return (
    <div>
      <Link to={`${path}/new/manual-booking`}>
        <button className="  shadow-md transition text-md duration-100 w-44 bg-green-500 hover:bg-green-400 text-white font-semibold py-2 rounded pr-1 flex flex-row justify-center align-center ">
          <MdPlaylistAdd className=" text-white text-2xl mr-1 self-center" />
          New Booking
        </button>
      </Link>
    </div>
  );
}
