import React, { Component, useState } from "react";
import EditOptionPanel from "../../EditOptionPanel";
import { MdPlaylistAdd } from "react-icons/md";
import {
  Link,
  useHistory,
  withRouter,
  RouteComponentProps,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import { formatFullDate } from "../../../utils/DateFormat";
import { GoSignIn } from "react-icons/go";
import { BiBookAdd } from "react-icons/bi";
import OccupancyRadial from "./Radial";
import OccupancyChart from "./BarChart";
import { fetchGetJSON } from "../../../api-requests/apiCallHelper";
import { Constants } from "../../../api-requests/apiLinkConstants";
import DashBar from "../DashBar";
import {
  OccupancyPercentage,
  SalesOccupancyResponse,
} from "../../../utils/Types";

// const apiPathExtensionConfirm = "/extension/confirm";
// const apiPathExtensionDelete = "/extension/delete";

// Notes:
// Tailwind has "last Child" class which allows the item in an array of children to have different styles
// Need to implement population loop which includes last child border bottom.
// Might have to switch to div format for tables, seems like tailwind has two different methods of styling tables
// One which is <table> tags and another with <div>. might need to experiment with this.

type Props = {
  userData: any;
  history: any;
};

type State = {
  booking_id: number;
  check_out: string;
  corporate_id: string;
  tenant_name: string;
  tenant_email: string;
  booking_total: string;
  toggleQuickView: boolean;
  toggleInvoice: boolean;
  user_name: string;
  check_in: Date;
  confirmed: boolean;
  flight_number: string;
  empty_units: number;
  occupancy: string;
  today_check_ins: number;
  today_check_outs: number;
  extensions: any[];
  occupancyData: OccupancyPercentage[];
};

class SalesDash extends Component<Props & RouteComponentProps, State> {
  constructor(props: Props & RouteComponentProps) {
    super(props);

    this.state = {
      booking_id: 1234,
      check_in: new Date(2020, 7, 8, 4, 20),
      check_out: "March 12th 2021",
      corporate_id: "Sky View Suites",
      tenant_name: "Riley Densmore",
      tenant_email: "rileydensmore@gmail.com",
      booking_total: "84",
      toggleInvoice: false,
      toggleQuickView: false,
      user_name: "Matthew",
      confirmed: false,
      flight_number: "AC1130",
      empty_units: 0,
      occupancy: "0",
      today_check_ins: 0,
      today_check_outs: 0,
      extensions: [],
      occupancyData: [],
    };

    this.handleInvoiceView = this.handleInvoiceView.bind(this);
  }

  handleQuickView = (state?: any) => {
    if (state && this.state.toggleQuickView === false) {
      this.setState({ toggleQuickView: true });
    } else {
      this.setState({ toggleQuickView: false });
    }
  };

  handleInvoiceView = (state?: any) => {
    if (state && this.state.toggleInvoice === false) {
      this.setState({ toggleInvoice: true });
    } else {
      this.setState({ toggleInvoice: false });
    }
  };

  componentDidMount = async () => {
    let occupancyData: SalesOccupancyResponse = await fetchGetJSON(
      `${Constants.API_PATH_SALES_DASHBOARD}`
    );

    if (occupancyData.status === "Success" && occupancyData.data) {
      let checkins = occupancyData.data.checkinsToday;
      let checkouts = occupancyData.data.checkoutsToday;
      let vacants = occupancyData.data.vacantsToday;
      this.setState({
        today_check_ins: checkins,
        today_check_outs: checkouts,
        empty_units: vacants,
      });

      if (occupancyData.data.occupancyPercentages.length > 0) {
        let occupancy: number =
          occupancyData.data.occupancyPercentages[0].total;
        occupancy = occupancy * 100;
        let occupancyString = occupancy.toFixed(2).toString();

        this.setState({
          occupancyData: occupancyData.data.occupancyPercentages,
          occupancy: occupancyString + "%",
        });
      }
    }
    // this.updateList();
    /* store.dispatch({ type: "contactListUpdate", listUpdate: this.updateList }); */
  };

  // Populate list entries with corporate contact relation from database
  // updateList = async () => {
  //   let extensionList = await fetchGetJSON(
  //     Constants.API_PATH_EXTENSION_GET_ALL_UNAPPROVED
  //   );
  //   this.setState({ extensions: extensionList });
  // };

  // async acceptExtension(id: number) {
  //   //this route will communicate with the back-end in order to accept the current extension
  //   let res = await fetchRequestJSON(
  //     "PUT",
  //     Constants.API_PATH_EXTENSION_CONFIRM,
  //     { id: id }
  //   );
  //   console.log(res);
  //   this.updateList();
  // }

  // async declineExtension(id: number) {
  //   let res = await fetchRequestJSON(
  //     "DELETE",
  //     Constants.API_PATH_EXTENSION_REJECT,
  //     { id: id }
  //   );
  //   console.log(res);
  //   this.updateList();
  // }

  render() {
    // let title = "Agreements";
    // let modalContent =
    //   "Select the properties that will be included in the agreement";
    let today = new Date();
    let time = today.getHours();
    // let currentMonth = new Intl.DateTimeFormat("en", { month: "long" }).format(
    //   today
    // );
    let dayTime = "";

    if (time >= 0 && time < 12) {
      dayTime = "Morning";
    } else if (time >= 12 && time < 17) {
      dayTime = "Afternoon";
    } else {
      dayTime = "Evening";
    }

    return (
      <div className=" font-display bg-f9 h-full lg:h-screen  lg:rounded-tl-lg pt-3 lg:pt-0 ">
        {/* <AgreementModal handleModalToggle={this.handleAgreement} onoff={this.state.toggleAgreement} title={title} content={modalContent} confirmButton={this.handleAgreement} /> */}

        {/* <BreadCrumb rootPage="Bookings" sideButton="agreement" subPage="" buttonAction={this.handleAgreement}/> */}
        <div className="fade-in px-4 lg:px-10  ">
          <div className=" flex flex-row justify-between font-circular pl-1 w-full pt-16 lg:pt-6 pb-4">
            <DashBar placeholder={""} />

            <div className="lg:flex flex-col hidden ">
              <span className="text-gray-900 font-medium">Sales Dashboard</span>
              <span className=" text-right text-sm text-gray-400 font-sofia">
                {formatFullDate(today)}
              </span>
            </div>
          </div>

          <div className="  w-full">
            <div className="pt-0  grid  grid-cols-1 sm:grid-cols-6 ">
              <div className=" col-start-1 col-end-5 flex flex-col">
                <span className=" pl-1 text-4xl leading-tight font-medium text-gray-500">
                  Good {dayTime},{" "}
                  <span className="font-thin text-gray-700">
                    {this.props.userData.user_f_name}
                  </span>
                </span>

                <span className=" ml-1 font-thin text-gray-600 font-sofia">
                  Let&apos;s Get To Work Today
                </span>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 w-full  my-5  ">
              <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4  px-1 py-0    ">
                <div className="flex flex-col gap-4  px-1 py-0 mb-4">
                  <div className="flex flex-row w-full h-full">
                    <div className="w-2/4 mr-5">
                      <StatBubbles
                        data={this.state.empty_units}
                        title={"Vacant Units"}
                        subtitle={"As of Today"}
                        button={"New Booking"}
                        linkEnd={"Bookings/New"}
                      />
                    </div>
                    <div className="w-2/4">
                      <StatBubbles
                        key={this.state.occupancy}
                        data={this.state.occupancy}
                        title={"Occupancy"}
                        subtitle={"As of This Month"}
                        button={"Graph"}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row w-full h-full">
                    <div className="w-2/4 mr-5">
                      <StatBubbles
                        data={this.state.today_check_ins}
                        title={"Check Ins"}
                        subtitle={"Today"}
                        button={"View Check Ins"}
                        linkEnd={"Check Ins"}
                      />
                    </div>
                    <div className="w-2/4">
                      <StatBubbles
                        data={this.state.today_check_outs}
                        title={"Check Outs"}
                        subtitle={"Today"}
                        button={""}
                        linkEnd={""}
                      />
                    </div>
                  </div>
                </div>
                {
                  <div className="">
                    <span className="pl-1 text-gray-700 font-medium text-xl">
                      Occupancy :
                    </span>
                    <OccupancyChart occupancyData={this.state.occupancyData} />
                  </div>
                }

                {
                  // commenting out the leads info for now as it is not currently needed
                }
                {/* <div className="  ">
                  <div className="flex flex-row justify-between w-full  items-end">
                    <span className=" pl-1 text-gray-700 font-medium text-xl">
                      New Leads:
                    </span>

                    {/* <div className="flex flex-row items-center text-lg  px-5 py-1  ">
                <button className="bg-white rounded-full shadow-md h-6 w-6 ">
                <IoIosArrowBack className="pl-1"/>
                </button>
                
                <span className="px-4 text-gray-500">{currentMonth}</span>
                <button className="bg-white rounded-full shadow-md h-6 w-6 flex flex-row items-center justify-center align-center">
                <IoIosArrowForward className="text-sm"/>
                </button>
              
              </div>
                  </div>

                  <div className=" my-3 h-96  overflow-y-auto shadow-md rounded-lg  w-full scroll-hide ">
                    <table
                      style={{ borderSpacing: "0" }}
                      className="border-separate  relative w-full table-auto bg-white     "
                    >
                      <thead className="border-b border-gray-400 ">
                        <tr className=" sticky top-0 bg-white text-left text-gray-500 border-2 border-gray-400   ">
                          <th className="sticky top-0  py-4 pl-5  font-thin border-b border-gray-400  ">
                            ID
                          </th>
                          <th className="sticky top-0  py-4 pl-5  font-thin border-b border-gray-400  ">
                            Contact
                          </th>
                          <th className="sticky top-0   py-3 font-thin border-b border-gray-400 ">
                            Email
                          </th>
                          <th className="sticky top-0   py-3 font-thin border-b border-gray-400 ">
                            Inquire Date
                          </th>
                          <th className=" sticky top-0  py-3 font-thin border-b border-gray-400 ">
                            Source
                          </th>
                          <th className="sticky top-0 text-right py-3 font-thin border-b border-gray-400 "></th>
                        </tr>
                      </thead>

                      <tbody className="divide-y">
                        <PaymentsToApprove
                          length={12}
                          viewInvoice={this.handleInvoiceView}
                        />
                      </tbody>
                    </table>
                  </div>
                </div> */}
              </div>

              {/* <div className=" grid grid-cols-1 gap-4  px-1 py-0    ">
                <div className="  ">
                  <div className="flex flex-row justify-between w-full  items-end">
                    <span className=" pl-1 text-gray-700 font-medium text-xl">
                      Tentative Extensions:
                    </span>
                  </div>

                  <div className=" my-3 h-96  overflow-y-auto shadow-md rounded-lg  w-full scroll-hide ">
                    <table
                      style={{ borderSpacing: "0" }}
                      className="border-separate  relative w-full table-auto bg-white     "
                    >
                      <thead className="border-b border-gray-400 ">
                        <tr className=" sticky top-0 bg-white text-left text-gray-500 border-2 border-gray-400   ">
                          <th className="sticky top-0  py-4 pl-5  font-thin border-b border-gray-400  ">
                            Booking ID
                          </th>
                          <th className="sticky top-0  py-4 pl-5  font-thin border-b border-gray-400  ">
                            Tenant
                          </th>
                          <th className="sticky top-0  py-4 pl-5  font-thin border-b border-gray-400  ">
                            Unit
                          </th>
                          <th className="sticky top-0   py-3 font-thin border-b border-gray-400 ">
                            Check Out
                          </th>
                          <th className="sticky top-0 text-right py-3 font-thin border-b border-gray-400 "></th>
                          <th className="sticky top-0   py-3 font-thin border-b border-gray-400 ">
                            Extension To{" "}
                          </th>
                          <th className="sticky top-0 text-right py-3 font-thin border-b border-gray-400 bg-white z-50 "></th>
                        </tr>
                      </thead>

                      <tbody className="divide-y">
                        <Extensions
                          extensionList={this.state.extensions}
                          accept={this.acceptExtension}
                          decline={this.declineExtension}
                          updateList={this.updateList}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          {/* Heading Info Area  */}
        </div>
      </div>
    );
  }
}

export default withRouter(SalesDash);

export function PaymentsToApprove(props: any) {
  let rows = [];
  let Payments = <></>;
  let altBg = "";
  let length = props.length;

  for (var i = 0; i < length; i++) {
    if (i % 2 != 1) {
      altBg = "bg-gray-50";
    } else {
      altBg = "bg-white";
    }

    //when linking to back end: remember to switch the key to the unique invoice id from the backend

    Payments = (
      <tr
        key={i.toString()}
        className={
          altBg + " w-full  hover:bg-gray-100 h-12 border-b border-gray-400  "
        }
      >
        <td className="px-5 py-3 font-thin w-10 text-green-600">{i}</td>
        <td className="px-5 py-3 font-thin ">Riley Densmore</td>
        <td className="py-3 text-gray-400 font-thin ">
          rileydensmore@gmail.com
        </td>
        <td className="py-3  font-thin ">{formatFullDate(new Date())}</td>
        <td className="py-3  font-thin ">Email</td>
        <td className=" w-16 px-1 py-4 ">
          <div className="flex flex-row justify-end">
            <LeadAdd linkEnd={"Manual-Booking"} />
          </div>
        </td>
      </tr>
    );

    rows.push(Payments);
  }

  return <>{rows}</>;
}

export function FinDashLinks(props: any) {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path, url } = useRouteMatch();
  let currentPageStyle = "text-gray-400";
  let currentPage = props.linkEnd;
  // path.endsWith("Dashboard")

  if (path.includes("/Dashboard/" + currentPage)) {
    currentPageStyle = "border-b border-green-500 text-gray-800";
  } else if (path.endsWith("Dashboard") && props.text == "Overview") {
    currentPageStyle = "border-b-2 border-green-500 text-gray-800";
  }

  return (
    <div className={currentPageStyle + " mr-5 "}>
      <Link to={`${path}/${props.linkEnd}`}>{props.text}</Link>
    </div>
  );
}

function StatBubbles(props: any) {
  let Button = <></>;
  let Data = props.data;
  let [redirect, setRedirect] = useState(false);

  let { path, url } = useRouteMatch();
  // let history = useHistory();
  let linkEnd = props.linkEnd;
  path = path.replace("Dashboard", linkEnd);

  // const handleSubmit = (event: any) => {
  //   event.preventDefault();
  //   history.push(path);
  // };

  const sendToCheckIn = () => {
    setRedirect(true);
  };

  if (props.button === "New Booking") {
    Button = (
      <button
        onClick={sendToCheckIn}
        className=" mr-4 whitespace-nowrap shadow-md transition duration-100 w-44 px-5 border-4 border-green-500 hover:bg-green-100 text-green-500 font-semibold py-2 rounded  flex flex-row justify-left align-left"
      >
        <MdPlaylistAdd className=" text-green-500 text-xl mr-1 self-center" />
        <span className=" text-sm ">{props.button}</span>
      </button>
    );
  }

  if (props.button === "View Check Ins") {
    Button = (
      <button
        onClick={sendToCheckIn}
        className="  mr-4 whitespace-nowrap shadow-md transition duration-100 w-44 px-5 border-4 border-green-500 hover:bg-green-100 text-green-500 font-semibold py-2 rounded  flex flex-row justify-center align-center"
      >
        <GoSignIn className=" text-green-500 text-lg mr-2 self-center" />
        <span className=" text-sm ">{props.button}</span>
      </button>
    );
  }

  if (props.button === "Graph") {
    Button = <OccupancyRadial data={props.data.split("%")[0]} />;
  }

  if (redirect && props.button === "View Check Ins") {
    return <Redirect to="/user/check ins"></Redirect>;
  } else if (redirect && props.button === "New Booking") {
    return <Redirect to="/user/bookings/new/manual-booking"></Redirect>;
  }

  let regularStyling = "flex flex-col items-left justify-left mt-5";
  if (props.button === "Graph") {
    regularStyling = "flex flex-col items-left justify-left";
  }

  return (
    <div className="flex flex-col appear  bg-white shadow-md   py-5 px-5 rounded-lg h-full">
      <div className="flex flex-col ">
        <span className="text-green-500 text-4xl leading-none font-bold  ">
          {Data}
        </span>

        <span className="text-green-700  text-base pt-1">{props.title}</span>
        <span className=" text-sm font-thin text-gray-400   ">
          {props.subtitle}
        </span>
      </div>

      <div className={regularStyling}>{Button}</div>
    </div>
  );
}

function LeadAdd(props: any) {
  // Takes Information from the current rows ID
  // then fills out information in the manual booking form flow
  // essentially same flow, except fields are automatically filled

  let { path, url } = useRouteMatch();
  let history = useHistory();
  let linkEnd = props.linkEnd;

  const handleLeadAdd = (event: any) => {
    event.preventDefault();
    alert("Auto Pulls Data Based On Row ID");
    let newPath = path.replace("dashboard", "bookings/new/" + linkEnd);
    history.push(newPath);
  };

  return (
    <BiBookAdd
      onClick={handleLeadAdd}
      className={
        "text-green-500 text-2xl mr-2 cursor-pointer hover:text-green-400"
      }
    />
  );
}

// export function Extensions(props: any) {
//   let rows = [];
//   let Payments = <></>;
//   let altBg = "";

//   for (let i = 0; i < props.extensionList.length; i++) {
//     if (i % 2 != 1) {
//       altBg = "bg-white";
//     } else {
//       altBg = "bg-white";
//     }

//     let index = i;

//     //when linking to back end: remember to switch the key to the unique invoice id from the backend

//     Payments = (
//       <tr
//         key={i.toString()}
//         className={
//           altBg + " w-full  hover:bg-gray-100 h-12 border-b border-gray-400  "
//         }
//       >
//         <td className="border-b  px-5 py-10 font-thin w-28 text-green-600">
//           {props.extensionList[i].booking_id}
//         </td>
//         <td className="border-b px-5 py-3 font-thin  w-80">
//           <div className="grid grid-cols-1   ">
//             <span className="truncate ">
//               {props.extensionList[i].booking.tenant?.first_name} {props.extensionList[i].booking.tenant?.last_name}
//             </span>
//             <span className="text-gray-400 truncate ">
//               {props.extensionList[i].booking.tenant?.email}
//             </span>
//           </div>
//         </td>
//         <td className="border-b  py-3 text-gray-400 font-thin  w-64">
//           <span
//             id="bookingConfirmed"
//             className="  cursor-pointer h-6 mr-2 bg-gray-700 text-white font-medium text-xs rounded-full py-1  inline-flex items-center text-center px-5"
//           >
//             {" "}
//             {props.extensionList[i].booking.unit.suite_name}{" "}
//           </span>
//         </td>
//         <td className="border-b  py-3  font-thin text-gray-500 w-40 ">
//           {formatStringDate(props.extensionList[i].booking.check_out)}
//         </td>
//         <td className="border-b  py-3 w-20  font-thin text-green-300 text-3xl ">
//           <RiChatForwardLine className="block m-auto" />
//         </td>
//         <td className=" border-b py-3  font-thin  text-blue-500 w-64 ">
//           {formatStringDate(props.extensionList[i].extend_until)}
//         </td>
//         <td className="border-b px-8 py-4 w-32">
//           <div className=" flex flex-row justify-between">
//             <ToolTipAlt message="Deny Request" position="bottom">
//               <MdCancel
//                 onClick={() => {
//                   props.decline(props.extensionList[i].id);
//                   props.updateList();
//                 }}
//                 className="text-3xl hover:opacity-50 cursor-pointer text-red-400 "
//               />
//             </ToolTipAlt>
//             <ToolTipAlt message="Approve Extension" position="bottom">
//               <AiOutlineCheckSquare
//                 onClick={() => {
//                   props.accept(props.extensionList[i].id);
//                   props.updateList();
//                 }}
//                 className="text-3xl hover:opacity-50 cursor-pointer text-green-600"
//               />
//             </ToolTipAlt>
//           </div>
//         </td>
//       </tr>
//     );

//     rows.push(Payments);
//   }

//   return <>{rows}</>;
// }
